/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, PriceWrap, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="--style3 bg--center --full" name={"intro"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102" content={"<span style='color: var(--color-dominant)'>Pricelist</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">Healthy body — healthy mind</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pricelist"}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="pl--02 pr--02">
              
              <Subtitle className="subtitle-box pb--30" content={"Training sessions"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"5x Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"10x Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"15x Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="pl--02 pr--02">
              
              <Subtitle className="subtitle-box pb--30" content={"Group lessons"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Training session (50 min.)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box ff--1 fs--18" style={{"maxWidth":367}} content={"<span style=\"color: var(--color-supplementary);\">$ 17</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1jz76ti --style3 bg--center --full" name={"intro"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72" content={"<span style='color: white'>Free <span style='color: var(--color-variable-1);'>initial consultation</span>.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 mt--20" content={"<span style='color: white'>Training sessions from $10 / hr.</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/en/contact"} content={"Let's meet"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 swpf--uppercase" content={"<span style=\"color: var(--color-custom-1);\">Peter Domanicky</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 mt--02" content={"<span style='color: var(--color-dominant)'>Personal trainer</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/en/"} content={"<span style='color: var(--color-dominant)'>Introduction</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/en/services"} content={"<span style='color: var(--color-dominant)'>Services</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/en/references"} content={"<span style='color: var(--color-dominant)'>References</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/en/contact"} content={"<span style='color: var(--color-dominant)'>Contact</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}